<template>
  <div
    class="login-page-fix"
    style="background-color: white"
    :style="`${IS_DOH_THEME ? `height: ${getScreenSize.height - 180}px` : ''}`"
  >
    <LoadingOverlay v-if="isLoginFetching" />
    <div
      class="row login-page-container"
      :style="`background-image: url(${getBanner})`"
    >
      <div class="col-md-6 p-0"></div>
      <div class="col-md-6 p-0 login-form-container">
        <CContainer
          class="login-container px-0"
          style="min-height: calc(100vh - 95px)"
        >
          <CCardGroup
            class="d-flex card-group has-loading-overlay justify-content-center"
          >
            <CCard class="sign-in-card p-4 border-0">
              <CCardBody>
                <CForm @submit.prevent="loginSubmit">
                  <div v-if="IS_DOH_THEME" style="text-align: center">
                    <img
                      style="width: 85%"
                      :src="getHeaderLogo"
                      @error="$event.target.src = '/img/doh.png'"
                      alt="logo"
                    />
                  </div>
                  <img
                    id="loginLogo"
                    v-else
                    style="width: 100%"
                    :src="getLoginLogo"
                    @error="$event.target.src = '/img/talentfind_logo2.png'"
                    alt="logo"
                  />
                  <div class="sign-title mt-5 text-center">
                    <strong>Sign in to </strong>
                    <strong v-if="IS_DOH_THEME">your account</strong>
                    <img
                      v-if="isAcronymBasedLogin && !IS_DOH_THEME"
                      :src="getCustomerLogo"
                      style="margin-bottom: 3px; width: 130px"
                      @error="
                        $event.target.outerHTML = `<span style='color: #dc0050'><strong>TALENT</strong>FIND<strong style='vertical-align: super; font-size: 13px'>TM</strong></span>`
                      "
                      alt="logo"
                    />
                    <span
                      v-if="!isAcronymBasedLogin && !IS_DOH_THEME"
                      style="color: #dc0050"
                    >
                      <strong>TALENT</strong>FIND<strong
                        style="vertical-align: super; font-size: 13px"
                        >TM</strong
                      >
                    </span>
                  </div>
                  <CRow class="mt-3">
                    <label class="ml-3">Email Address</label>
                    <CCol class="text-right">
                      <span
                        class="login-hint text-primary"
                        v-c-tooltip="'Your email is your username!'"
                        >Login Hint</span
                      >
                    </CCol>
                  </CRow>
                  <CInput
                    name="login-email"
                    autocomplete="username email"
                    v-model="username"
                    tabindex="1"
                  ></CInput>
                  <CRow>
                    <label class="ml-3 pt-2">Password</label>
                    <CCol class="text-right">
                      <CButton
                        @click="redirectToForgot"
                        color="link"
                        class="px-0"
                        >Forgot password?</CButton
                      >
                    </CCol>
                  </CRow>
                  <CInput
                    name="login-password"
                    type="password"
                    v-model="password"
                    autocomplete="curent-password"
                    tabindex="2"
                  ></CInput>
                  <div v-if="!getOrgAcronym">
                    <CButton
                      class="ml-5"
                      color="link"
                      @click="routeToListOrganisation"
                    >
                      Login as Facility/Candidate
                    </CButton>
                  </div>
                  <CRow class="justify-content-end mb-4">
                    <CButton
                      name="login-submit"
                      :disabled="isLoginFetching"
                      type="submit"
                      class="px-4 mr-3 btn-primary"
                      >{{
                        !isLoginFetching ? "Sign in" : "Loading..."
                      }}</CButton
                    >
                  </CRow>
                  <div
                    v-if="isAcronymBasedLogin"
                    class="d-flex justify-content-center"
                  >
                    <p style="font-size: 10px" class="p-0">
                      Click here to
                      <CButton
                        @click="redirectToUnsubsribe"
                        color="link"
                        class="px-0"
                        style="font-size: 10px"
                        >unsubscribe</CButton
                      >
                    </p>
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    v-if="isAcronymBasedLogin"
                  >
                    <CButton
                      name="view-and-apply-jobs"
                      color="link"
                      class="font-weight-bold active px-0 fs-15 text-primary"
                      @click="redirectToPublicJobBoard"
                      >View & Apply Jobs<span class="small-text mx-1"
                        ><sup>New</sup></span
                      ></CButton
                    >
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CContainer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  Role,
  CANDIDATE_URL,
  FACILITY_CANDIDATE_URL,
  LOGIN_URL,
  IS_DOH_THEME,
  getLocalOrgDetail,
  CONSTANT as CONST,
  generateLocalFileUrl
} from "@/helpers/helper";

export default {
  name: "Login",
  data() {
    return {
      Role,
      CANDIDATE_URL,
      FACILITY_CANDIDATE_URL,
      IS_DOH_THEME,
      getLocalOrgDetail,
      username: "",
      password: "",
      getOrgAcronym: "",
      generateLocalFileUrl,
    };
  },
  computed: {
    ...mapGetters([
      "getUserData",
      "isLoginFetching",
      "getRoleScopes",
      "getImages",
      "isAcronymBasedLogin",
    ]),
   getBanner() {
      return generateLocalFileUrl(this.getImages?.banner);
    },
    getCustomerLogo() {
      return generateLocalFileUrl(this.getImages?.customer_logo);
    },
    getHeaderLogo() {
      return generateLocalFileUrl(this.getImages?.header_logo);
    },
    getLoginLogo() {
      return generateLocalFileUrl(this.getImages?.login_logo);
    },
    getScreenSize() {
      return {
        width: screen.availWidth,
        height: screen.availHeight,
      };
    },
  },
  mounted() {
    const {
      params: { org_name: pid },
    } = this.$router.currentRoute;
    this.getOrgAcronym = pid || "";
    if (this.getRoleScopes.length) {
      if (
        this.getRoleScopes[0] === Role.systemCandidate ||
        this.getRoleScopes[0] === Role.customerCandidate
      ) {
        this.$router.push("/home");
      } else if (this.getRoleScopes[0] === Role.systemAdmin) {
        this.$router.push(this.CANDIDATE_URL);
      } else {
        this.$router.push(this.FACILITY_CANDIDATE_URL);
      }
    }
    this.routeToTalentFind();
  },
  methods: {
    ...mapActions([
      "login",
      "showToast",
      "resetCandidate",
      "isEmailBelongsToAcronym",
    ]),
    loginSubmit() {
      if (this.username && this.password) {
        this.isEmailBelongsToAcronym(this.username.toLowerCase().trim()).then(
          (res) => {
            if (res.data) {
              this.login({ username: this.username, password: this.password });
            }
          }
        );
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill the credentials",
        });
      }
      // this.$store.commit("SET_SHOW_ALERT", true);
    },
    redirectToForgot() {
      this.$router.push({
        path: `${
          this.isAcronymBasedLogin ? `${LOGIN_URL()}/forgot` : "/forgot"
        }`,
      });
    },
    redirectToUnsubsribe() {
      this.$router.push({ path: LOGIN_URL() + "/unsubscribe" });
    },
    redirectToPublicJobBoard() {
      let path = ""
      if (getLocalOrgDetail()?.customer_type_id === CONST.CUSTOMER_TYPES.educational_institute) {
        path = `/public/job-board/${CONST.CUSTOMERS.super_customer}`
      }
      else {
        path = `/public/job-board/${getLocalOrgDetail()?.customer_uid}${
          getLocalOrgDetail()?.organisation_id
            ? `/org/${getLocalOrgDetail()?.organisation_id}`
            : ""
        }`
      }
      this.$router.push({ path });
    },
    routeToListOrganisation() {
      this.$router.push({ name: "ListOrganisation" });
    },
    routeToTalentFind() {
      if (window.location.host === "talentfindworld.com") {
        this.$router.push({
          path: `${"/tfw"}`,
        });
      }
    },
  },
  watch: {
    "$route.name": function () {
      this.routeToTalentFind();
      this.page = this.$router.currentRoute.name;
      const {
        params: { org_name: pid },
      } = this.$router.currentRoute;
      this.getOrgAcronym = pid || "";
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1000px) {
  #bg-img {
    display: none;
  }
}
.login-container {
  .card-group {
    .sign-in-card {
      max-width: 400px;
      @media (min-width: 1000px) {
        margin-top: 50px;
      }
      .sign-title {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
.login-hint {
  font-size: 0.65rem;
  color: #dd3651;
  font-weight: 400;
  cursor: pointer;
}
.login-page-fix {
  margin-top: -22px;
  overflow-x: hidden;
}

@media (min-width: 360px) {
  .login-page-fix {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.login-page-container {
  background-size: contain;
  background-repeat: no-repeat;
  -moz-background-size: contain;
  -ms-background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;
  background-size: contain;

  .bg-img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}
</style>
